import { Layout, Formtitle, Fifpl, Dpc, PersoFin, Cpf, Other } from '../components'
import { Grid, Button } from '@mui/material'
import { FormType, FunderType } from '../utils/sharedTypes'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { client } from '../utils/client'
import Bugsnag from '@bugsnag/js'
import { NotFundable } from '../components'
import { funders } from '../utils/constants'

type FinancementPropsType = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
}

const Financement = ({ form, setForm }: FinancementPropsType) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleChange = (key: string, value: string | null) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]
      return {
        ...prevState,
        data: {
          ...prevState.data,
          funding_organism: {
            ...prevState.data.funding_organism,
            code: value,
          },
        },
        errors: errors,
      }
    })
  }

  useEffect(() => {
    if (form.data.product?.product_code && form?.data?.profession?.code) {
      setIsLoading(true)
      let url = `/registration/content/funding_organisms/?product_code=${form.data.product.product_code}`
      if (form.professions.length !== 0 && form.professions[0]?.code !== 'unknown') {
        url += `&profession=${form?.data?.profession?.code}`
      }

      client
        .get(url)
        .then((resp) => {
          const data = resp.data as {
            results: FunderType[]
          }
          let code = null

          const sortedFunders = data.results
            .filter((f) => {
              return funders.find((p) => p.code === f.code)
            })
            .sort((a, b) => {
              const nameA = funders.find((f) => f.code === a.code)?.label
              const nameB = funders.find((f) => f.code === b.code)?.label
              if (nameA && nameB) {
                if (nameA < nameB) return -1
                if (nameA > nameB) return 1
              }
              return 0
            })
          setForm((prevState) => {
            return {
              ...prevState,
              funders: [
                ...sortedFunders,
                {
                  code: null,
                  name: 'Etude personnalisée',
                } as FunderType,
              ],
            }
          })

          if (data.results.length >= 1) code = sortedFunders[0].code

          handleChange('code', form.data.funding_organism?.code ?? code)
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [form.data.product?.product_code, form?.data?.profession?.code])

  useEffect(() => {
    if (form.data.funding_organism && form.data.global_id) {
      client
        .patch(`/registration/${form.data.global_id}/`, {
          funding_organism: form.data.funding_organism,
        })
        .then((resp) => {
          console.log('resp', resp)
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })
    }
  }, [form.data.funding_organism?.code])

  return (
    <Layout form={form.data} steps={form.steps}>
      <Formtitle
        title="Financement"
        subtitle={
          form.data.registration_type == 'PreConfiguredRegistration'
            ? 'Veuillez confirmez les informations ci-dessous'
            : form.data.funding_organism?.code === null
            ? "Vous avez besoin d'aide & préférez être accompagné(e) par un conseiller ?"
            : 'Remplissez le formulaire ci-dessous pour poursuivre votre inscription'
        }
      />
      {form.data.registration_type == 'PreConfiguredRegistration' ? (
        <Grid item container spacing={1} sx={{ justifyContent: 'flex-start', mb: 4 }}>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              sx={{
                height: 56,
              }}
            >
              {form.data.funding_organism?.code === 'FIFPL' && form.data.personal_funding
                ? `Mixte (${funders.find((p) => p.code === form.data.funding_organism?.code)?.label || ''}, personnel)`
                : funders.find((p) => p.code === form.data.funding_organism?.code)?.label || '-'}
            </Button>
          </Grid>
        </Grid>
      ) : (
        form.funders.length > 1 && (
          <Grid item container spacing={1} sx={{ justifyContent: 'flex-start', mb: 4 }}>
            {form.funders.map((f) => {
              return (
                <Grid item key={f.code}>
                  <Button
                    variant={form.data.funding_organism?.code === f.code ? 'contained' : 'outlined'}
                    fullWidth
                    onClick={() => {
                      handleChange('code', f.code)
                    }}
                    sx={{
                      height: 56,
                    }}
                  >
                    {funders.find((p) => p.code === f.code)?.label || '-'}
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        )
      )}

      {!isLoading && (
        <Grid item>
          {form.data.funding_organism?.code === 'FIFPL' && <Fifpl form={form} setForm={setForm} />}
          {form.data.funding_organism?.code === 'ALMA' && <PersoFin form={form} setForm={setForm} />}
          {form.data.funding_organism?.code === 'DPC' && <Dpc form={form} setForm={setForm} />}
          {form.data.funding_organism?.code === 'CPF' && <Cpf form={form} setForm={setForm} />}
          {form.data.funding_organism?.code === null && <Other form={form} setForm={setForm} />}
          {form.funders.length === 0 && !form.data.personal_funding && form.data.funding_organism?.code === 'FIFPL' && (
            <NotFundable form={form} setForm={setForm} />
          )}
        </Grid>
      )}
    </Layout>
  )
}

export default Financement
