import { EnterpriseFormType } from './sharedTypes'

export const StripePublicKey = 'pk_live_fi80GlEzec4VsRsyEhULGyx6'

export const SFTypeLMSTypeMapping = {
  alma_sale: 'alma',
  b2b_sale: 'b2b',
  cpf_sale: 'cpf',
  dpc_sale: 'dpc',
  fafpm_sale: 'fafpm',
  fifpl_sale: 'fifpl',
  gocardless_sale: 'gocardless',
  kairos_sale: 'kairos',
  opco_sale: 'opco',
  stripe_sale: 'stripe',
  transfer_sale: 'gocardless',
  other: 'other',
  Other: 'other',
}

export const funders = [
  { label: 'FIFPL', code: 'FIFPL' },
  { label: 'Financement personnel', code: 'ALMA' },
  { label: 'ANDPC', code: 'DPC' },
  { label: 'CPF - Mon compte formation', code: 'CPF' },
  { label: 'Etude personnalisée', code: null },
]

export const learningPhoneNumber = '0162010478'
export const santePhoneNumber = '0176498048'

export const FormDefaultState = {
  data: {
    id: null,
    installment_schedule: null,
    global_id: '',
    registration_type: 'SelfRegistration',
    pre_registration: false,
    personal_funding: null,
    default_personal_funding: null,
    first_name: '',
    last_name: '',
    mobile_phone: '',
    email: '',
    profession: { code: '' },
    exercice: '',
    rgpd: false,
    budget: false,
    perso_cgu_cgv: false,
    perso_program: false,
    perso_signature: false,
    perso_checkbox: false,
    alma_contract: '',
    alma_contract_signed_at: null,
    alma_cgu_cgv: false,
    alma_payment_id: '',
    alma_payment_url: '',
    alma_installments_count: 1,
    alma_paid: false,
    dpc_submitted: false,
    dpc_rpps: '',
    dpc_contract: '',
    dpc_contract_signed_at: null,
    dpc_cgu_cgv: false,
    stripe_contract: '',
    stripe_contract_signed_at: null,
    stripe_cgu_cgv: false,
    required_signature: null,
    attestation_urssaf: '',
    birthdate: null,
    entrypoint_url: null,
    lead_source: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
    utm_content: null,
    adset_id: null,
    theme: null,
    cpf_idn_status: '',
    appointment_dt: null,
    cpf_starting_date: null,
    cpf_price: null,

    // Billing information
    invoice_company_name: '',
    invoice_first_name: '',
    invoice_last_name: '',
    invoice_street: '',
    invoice_postal_code: '',
    invoice_city: '',

    funding_organism: {
      code: null,
    },
    session: {
      global_id: null,
    },
    product: {
      id: 0,
      product_code: '',
      name: '',
      description: '',
      listing_price: 0,
      duration: 0,
      program_url: '',
      dpc_action_number: '',
      cpf_action_string: '',
    },
    products: [],
    product_catalog: null,
    product_catalogs: [],
    funding_organism_registrations: [],
    funding_organism_registration_validated: false,
  },
  professions: [],
  sessions: [],
  funders: [],
  steps: [
    {
      label: 'Informations personnelles',
      url: 'information',
    },
    {
      label: 'Financement',
      url: 'financement',
    },
    {
      label: 'Récapitulatif',
      url: 'recapitulatif',
    },
  ],
  errors: {},
}

export const ApprenticeshipFormDefaultState = {
  data: {
    global_id: '',
    first_name: '',
    former_salary: null,
    former_weekly_work_duration: null,
    last_name: '',
    nir: '',
    mobile_phone: '',
    email: '',
    street: '',
    postal_code: '',
    city: '',
    gender: '',
    country: '',
    nationality: '',
    theme: null,
    entrypoint_url: null,
    lead_source: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
    utm_content: null,
    birth_date: '',
    birth_city: '',
    birth_department: '',
    birth_country: '',
    cover_letter: '',
    curriculum_vitae: '',
    has_apprenticeship_company: true,
    apprenticeship_company_name: '',
    apprenticeship_company_postal_code: '',
    apprenticeship_company_city: '',
    apprenticeship_company_street: '',
    apprenticeship_company_country: '',
    apprenticeship_company_responsable_first_name: '',
    apprenticeship_company_responsable_last_name: '',
    apprenticeship_company_responsable_email: '',
    apprenticeship_company_responsable_mobile_phone: '',
    is_born_abroad: null,
    has_legal_representative: null,
    legal_representative_first_name: '',
    legal_representative_last_name: '',
    legal_representative_email: '',
    legal_representative_mobile_phone: '',
    legal_representative_street: '',
    legal_representative_postal_code: '',
    legal_representative_city: '',
    legal_representative_country: '',

    product: {
      id: 0,
      product_code: 'sec_med',
      name: 'Secrétariat médical',
      description: '',
      listing_price: 0,
      duration: 0,
      program_url: '',
      dpc_action_number: '',
      cpf_action_string: '',
    },
    products: [],
    is_high_level_athlete: false,
    is_disabled_worker: false,
    want_to_create_enterprise: false,
    social_regime: '',
    situation_before_contract: '',
    last_student_year_outcome: '',
    last_diploma_title: '',
    last_diploma_name: 'none',
    last_diploma_prepared: 'none',

    want_to_move_out: false,
    move_out_city: '',
    move_out_postal_code: '',

    authorized_contact_with_companies: false,
    engaged_to_follow_training: false,
    is_former_apprenticeship: false,

    apprenticeship_type: null,
    is_registered_to_pole_emploi: false,
    pole_emploi_registration_number: '',
    pole_emploi_duration: '',
    social_minimum_type: '',
  },
  steps: [
    {
      label: 'Informations personnelles',
      url: 'information',
    },
    {
      label: "Votre entreprise d'accueil",
      url: 'entreprise',
    },
    {
      label: 'Votre profil apprenant',
      url: 'profile',
    },
    {
      label: 'Votre situation',
      url: 'situation',
    },
  ],
  errors: {},
  diplomas: [],
  last_student_year_outcomes: [],
}

export const EnterpriseFormDefaultState: EnterpriseFormType = {
  data: {
    global_id: '',
    first_name: '',
    last_name: '',
    mobile_phone: '',
    email: '',
    theme: null,
    entrypoint_url: null,
    lead_source: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
    utm_content: null,

    product: {
      id: 0,
      product_code: 'sec_med',
      name: 'Secrétariat médical',
      description: '',
      listing_price: 0,
      duration: 0,
      program_url: '',
      dpc_action_number: '',
      cpf_action_string: '',
    },
    products: [],

    cerfa_company_name: '',
    cerfa_immatriculation_place: '',
    cerfa_siret: '',
    cerfa_convention_number: '',
    cerfa_employer_type: '',
    cerfa_employer_specific: '',
    cerfa_naf: '',
    cerfa_total_employees: '',
    cerfa_signatory: '',
    cerfa_signatory_first_name: '',
    cerfa_signatory_last_name: '',
    cerfa_signatory_function: '',
    cerfa_opco: '',
    cerfa_company_phone: '',
    cerfa_company_email: '',
    cerfa_company_street: '',
    cerfa_company_postal_code: '',
    cerfa_company_city: '',
    cerfa_company_country: '',
    cerfa_tutor_first_name: '',
    cerfa_tutor_last_name: '',
    cerfa_tutor_birth_date: '',
    cerfa_tutor_email: '',
    cerfa_tutor_mobile_phone: '',
    cerfa_tutor_current_job: '',
    cerfa_tutor_highest_diploma: '',
    cerfa_tutor_highest_level_code: '',
    cerfa_contract_type: '',
    cerfa_derogation_type: '',
    cerfa_amendment_contract_number: '',
    cerfa_contract_starting_date: '',
    cerfa_formation_starting_date: '',
    cerfa_amendment_date: '',
    cerfa_contract_ending_date: '',
    cerfa_weekly_work_duration: '',
    cerfa_remuneration_first_year_starting_date: '',
    cerfa_remuneration_first_year_ending_date: '',
    cerfa_remuneration_first_year_percentage: '',
    cerfa_remuneration_first_year_type: '',
    cerfa_remuneration_first_year_gross_salary: '',
    cerfa_remuneration_second_year_starting_date: '',
    cerfa_remuneration_second_year_ending_date: '',
    cerfa_remuneration_second_year_percentage: '',
    cerfa_remuneration_second_year_type: '',
    cerfa_supplementary_pension_fund: '',
    cerfa_benefit_in_kind_food: '',
    cerfa_benefit_in_kind_accommodation: '',
    cerfa_know_school_days: null,
    cerfa_school_days: '',

    apprenticeship_type: null,

    cerfa_particular_employer: false,
    cerfa_urssaf: '',
    cerfa_prevention_organism: '',
    cerfa_project_number: '',
    cerfa_contract_nature: '',
    cerfa_job_title: '',
    cerfa_classification: '',
    cerfa_level: '',
    cerfa_hierarchical_coefficient: '',
    cerfa_trial_period_duration: '',
  },

  steps: [
    {
      label: "Informations sur l'entreprise",
      url: 'information',
    },
    {
      label: "Maître d'apprentissage",
      url: 'maitre-apprentissage',
    },
    {
      label: 'Contrat',
      url: 'contrat',
    },
  ],

  errors: {},
}

export const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]

export const situation_before_contract = [
  {
    id: 'unknown',
    label: 'Je ne sais pas',
  },
  {
    id: 'school',
    label: 'Scolaire',
  },
  {
    id: 'prepa_apprentissage',
    label: 'Prépa apprentissage',
  },
  {
    id: 'student',
    label: 'Etudiant',
  },
  {
    id: 'apprenticeship_contract',
    label: 'Contrat d’apprentissage',
  },
  {
    id: 'professionalization_contract',
    label: 'Contrat de professionnalisation',
  },
  {
    id: 'aided_contract',
    label: 'Contrat aidé',
  },
  {
    id: 'cfa_formation',
    label:
      'En formation au CFA sous statut de stagiaire de la formation professionnelle, avant conclusion d’un contrat d’apprentissage (L6222-12-1 du code du travail)',
  },
  {
    id: 'cfa_formation_no_contract',
    label:
      'En formation, au CFA sans contrat sous statut de stagiaire de la formation professionnelle, à la suite d’une rupture d’un précédent contrat (5° de L6231-2 du code du travail)',
  },
  {
    id: 'other_situations',
    label: 'Autres situations sous statut de stagiaire de la formation professionnelle',
  },
  {
    id: 'employee',
    label: 'Salarié',
  },
  {
    id: 'job_seeker',
    label: 'Personne à la recherche d’un emploi (inscrite ou non à Pôle Emploi)',
  },
  {
    id: 'inactive',
    label: 'Inactif',
  },
]

export const situation_before_contract_pro = [
  {
    id: 'unknown',
    label: 'Je ne sais pas',
  },
  {
    id: 'school',
    label: 'Scolaire',
  },
  {
    id: 'civis',
    label: 'Jeune bénéficiare du CIVIS',
  },
  {
    id: 'student',
    label: 'Etudiant',
  },
  {
    id: 'apprenticeship_contract',
    label: "Contrat d'apprentissage",
  },
  {
    id: 'professionalization_contract',
    label: 'Contrat de professionnalisation',
  },
  {
    id: 'aided_contract',
    label: 'Salarié en contrat aidé : CUI-CIE, CUI-CAE',
  },
  {
    id: 'cfa_formation',
    label: 'Stagiaire de la formation professionnelle',
  },
  {
    value: 'employee',
    label: 'Salarié (y compris intérimaire et salariés à temps partiel)',
  },
  {
    id: 'job_seeker',
    label: 'Personne à la recherche d’un emploi',
  },
  {
    id: 'inactive',
    label: 'Inactif',
  },
]

// export const exercices = [
//   { label: 'Employé', code: 'EMPLOYE' },
//   { label: 'Libéral', code: 'LIBERAL' },
//   { label: 'Autre', code: 'OTHER' },
// ]

// export const ExternalRegistrationFormDefaultState = {
//   data: {
//     global_id: '',
//     first_name: '',
//     last_name: '',
//     mobile_phone: '',
//     email: '',
//     profession: { code: '' },
//     exercice: '',
//     rgpd: false,
//     budget: false,
//     perso_cgu_cgv: false,
//     perso_program: false,
//     perso_signature: false,
//     perso_checkbox: false,
//     alma_contract: '',
//     alma_contract_signed_at: null,
//     alma_payment_id: '',
//     alma_payment_url: '',
//     alma_installments_count: 1,
//     alma_paid: false,
//     dpc_submitted: false,
//     dpc_rpps: '',
//     attestation_urssaf: '',
//     birthdate: null,
//     entrypoint_url: null,
//     lead_source: null,
//     utm_source: null,
//     utm_medium: null,
//     utm_campaign: null,
//     utm_term: null,
//     utm_content: null,
//     theme: null,
//     funding_organism: {
//       code: null,
//     },
//     session: {
//       global_id: null,
//     },
//     product: {
//       product_code: '',
//       name: '',
//       description: '',
//       listing_price: 0,
//       duration: 0,
//       program_url: '',
//       dpc_action_number: '',
//     },
//   },
//   professions: [],
//   sessions: [],
//   funders: [],
//   steps: [
//     {
//       label: 'Informations personnelles',
//       url: 'information',
//     },
//     {
//       label: 'Financement',
//       url: 'financement',
//     },
//     {
//       label: 'Récapitulatif',
//       url: 'recapitulatif',
//     },
//   ],
//   errors: {},
// }
