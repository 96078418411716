import { useEffect, useRef, useState } from 'react'
import {
  Divider,
  Grid,
  Typography,
  TextField,
  Button,
  useTheme,
  InputAdornment,
  Link,
  CircularProgress,
} from '@mui/material'
import { Formtitle, CallUs } from '../components'
import { FormType } from '../utils/sharedTypes'
import { emailValidator, phoneNumberValidator } from '../utils/validators'
import { client } from '../utils/client'
import { ReactComponent as WalterLearning } from '../assets/WalterLearning.svg'
import { ReactComponent as WalterSanteColor } from '../assets/WalterSanteColor.svg'
import { ReactComponent as Retour } from '../assets/Retour.svg'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { learningPhoneNumber, santePhoneNumber } from '../utils/constants'
import { BsCheck } from 'react-icons/bs'
import { MdPhoneEnabled } from 'react-icons/md'
import Bugsnag from '@bugsnag/js'

type RecommandationPropsType = {
  form: FormType
}

type DataType = {
  first_name: string
  last_name: string
  email: string
  mobile_phone: string
}

type RecoFormType = {
  data: DataType
  errors: Record<string, string>
}

const Recommandation = ({ form }: RecommandationPropsType) => {
  const MAX_RETRY = 6
  const PAUSE_BETWEEN_RETRY = 5000
  const RETRY = useRef(0)
  const theme = useTheme()
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const [recommanded, setRecommanded] = useState(false)
  const [reco, setReco] = useState<RecoFormType>({
    data: {
      first_name: '',
      last_name: '',
      email: '',
      mobile_phone: '',
    },
    errors: {},
  })
  const [magicLink, setMagicLink] = useState<string | null | undefined>(undefined)

  const handleChange = (key: string, value: string | boolean | Record<string, string>) => {
    setReco((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'email':
          if (!emailValidator(value as string)) {
            errors['email'] = 'Veuillez renseigner un e-mail valide'
          }
          break
        case 'mobile_phone':
          if (!phoneNumberValidator(value as string)) {
            errors['mobile_phone'] = 'Veuillez renseigner un numéro de téléphone valide'
          }
          break
        case 'first_name':
          if ((value as string).length === 0) {
            errors['first_name'] = 'Veuillez renseigner un prénom'
          }
          break
        case 'last_name':
          if ((value as string).length === 0) {
            errors['last_name'] = 'Veuillez renseigner un nom de famille'
          }
          break
        default:
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const recommandationWording = (): {
    title: string
    subtitle: string
    button?: {
      text: string
      href?: string
    }
  } => {
    switch (form.data.funding_organism?.code) {
      case 'FIFPL':
        if (form.data.registration_type == 'PreConfiguredRegistration') {
          return {
            title: 'Merci, c’est tout bon !',
            subtitle: `Félicitations, votre ${
              form.data.pre_registration ? 'pré-' : ''
            }inscription est validée. Vous allez recevoir rapidement vos accès personnels à la plateforme de formation, directement sur votre adresse email.`,
          }
        } else {
          return {
            title: "Merci, c'est presque bon pour nous !",
            subtitle:
              'Notre équipe d’accompagnement vérifie la validité du document et vous recontactera pour valider votre inscription.',
          }
        }

      case 'DPC':
        if (form.data.registration_type == 'PreConfiguredRegistration') {
          if (magicLink) {
            return {
              title: 'Merci, c’est tout bon !',
              subtitle: `Félicitations, votre ${
                form.data.pre_registration ? 'pré-' : ''
              }inscription est validée.\nCliquez sur le bouton ci-dessous pour accéder à votre compte.`,
              button: {
                text: 'Accéder à mon compte',
                href: magicLink ?? '',
              },
            }
          } else if (magicLink !== null) {
            return {
              title: `Votre compte est\nen cours de création`,
              subtitle: `Nous vous remercions pour votre ${
                form.data.pre_registration ? 'pré-' : ''
              }inscription.\nVotre compte est en cours de création...`,
            }
          } else {
            return {
              title: 'Merci, c’est tout bon !',
              subtitle: `Félicitations, votre ${
                form.data.pre_registration ? 'pré-' : ''
              }inscription est validée. Vous allez recevoir rapidement vos accès personnels à la plateforme de formation, directement sur votre adresse email.`,
            }
          }
        } else {
          return {
            title: 'Merci, c’est bon pour nous !',
            subtitle: `Félicitations, votre inscription est validée. Vous allez recevoir rapidement vos accès personnels à la plateforme de formation, directement sur votre adresse email.`,
            //`Nous attendons la validation de votre ${form.data.pre_registration ? 'pré-' : ''}inscription par l’ANDPC. Notre équipe d’accompagnement vous recontactera rapidement pour confirmer votre inscription.`,
          }
        }

      case 'ALMA':
        if (form.data.registration_type == 'PreConfiguredRegistration') {
          if (magicLink) {
            return {
              title: 'Merci, c’est tout bon !',
              subtitle: `Félicitations, votre ${
                form.data.pre_registration ? 'pré-' : ''
              }inscription est validée.\nCliquez sur le bouton ci-dessous pour accéder à votre compte.`,
              button: {
                text: 'Accéder à mon compte',
                href: magicLink ?? '',
              },
            }
          } else if (magicLink !== null) {
            return {
              title: `Votre compte est\nen cours de création`,
              subtitle: `Nous vous remercions pour votre ${
                form.data.pre_registration ? 'pré-' : ''
              }inscription.\nVotre compte est en cours de création...`,
            }
          } else {
            return {
              title: 'Merci, c’est tout bon !',
              subtitle: `Félicitations, votre ${
                form.data.pre_registration ? 'pré-' : ''
              }inscription est validée. Vous allez recevoir rapidement vos accès personnels à la plateforme de formation, directement sur votre adresse email.`,
            }
          }
        } else {
          return {
            title: 'Merci, c’est tout bon !',
            subtitle: `Félicitations, votre ${
              form.data.pre_registration ? 'pré-' : ''
            }inscription est validée. Vous recevrez rapidement vos accès personnels à la plateforme de formation, directement sur votre adresse email. Un conseiller va prendre contact avec vous pour vous aider à démarrer au mieux votre parcours d’apprentissage.`,
          }
        }

      case null:
      case 'CPF': {
        if (form.data.appointment_dt && form.data.cpf_idn_status !== 'Oui') {
          return {
            title: "Merci, c'est presque bon pour nous !",
            subtitle: `Nous vous confirmons votre rendez-vous du ${
              new Date(form.data.appointment_dt).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }) +
              ' à ' +
              new Date(form.data.appointment_dt)
                .toLocaleTimeString('fr-FR', {
                  hour: '2-digit',
                  minute: '2-digit',
                })
                .replace(':', 'h')
            }. Notre équipe commerciale vous contactera par téléphone. Un email de confirmation vous a été envoyé.`,
          }
        }

        if (!form.data.appointment_dt && form.data.cpf_idn_status !== 'Oui') {
          return {
            title: "Merci, c'est presque bon pour nous !",
            subtitle:
              'Nous vous confirmons votre demande de rappel. Notre équipe commerciale vous contactera par téléphone rapidement.',
          }
        }
        return {
          title: "Merci, c'est presque bon pour nous !",
          subtitle:
            'Notre équipe commerciale vous contactera par téléphone pour finaliser votre inscription rapidement.',
        }
      }

      default:
        return {
          title: 'Merci, c’est presque bon pour nous !',
          subtitle:
            'Notre équipe d’accompagnement vérifie la validité du document et vous recontactera pour valider votre inscription.',
        }
    }
  }

  const { title, subtitle, button } = recommandationWording()

  const submit = () => {
    client
      .post(`/registration/${form.data.global_id}/sponsorships/`, reco.data)
      .then((resp) => {
        if (resp.status === 201) {
          setRecommanded(true)
          window.scrollTo(0, 0)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (form.data.registration_type === 'PreConfiguredRegistration') {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setTimeout(() => {}, PAUSE_BETWEEN_RETRY)

      const interval = setInterval(() => {
        if (RETRY.current >= MAX_RETRY) {
          clearInterval(interval)
          if (!magicLink) {
            setMagicLink(null)
          }
          return
        }

        client
          .get(`/registration/${form.data.global_id}/magic_link/`)
          .then((resp) => {
            const data = resp.data as { magic_link: string | null }

            if (data.magic_link === null) {
              setMagicLink(null)
            } else if (data.magic_link !== 'pending') {
              setMagicLink(data.magic_link)
              clearInterval(interval)
            }
          })
          .catch((err: Error) => {
            Bugsnag.notify(err)
            console.error(err)
          })
          .finally(() => {
            RETRY.current++
          })
      }, PAUSE_BETWEEN_RETRY)
    }
  }, [form.data.registration_type, RETRY.current])

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', p: 2 }} ref={animateRef}>
      {!recommanded && (
        <Grid item xs={12} md={6} sx={{ display: recommanded ? 'none' : 'block', mt: { xs: 15, md: 0 } }}>
          <Grid container justifyContent="center">
            <Grid
              item
              sx={{
                background: theme.palette.success.main,
                height: 80,
                width: 80,
                borderRadius: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {form.data.funding_organism?.code === 'DPC' &&
              form.data.registration_type === 'PreConfiguredRegistration' &&
              typeof magicLink === 'undefined' ? (
                <CircularProgress size={30} style={{ color: 'white' }} />
              ) : (
                <BsCheck size="40px" style={{ color: 'white' }} />
              )}
            </Grid>
          </Grid>
          <Grid item maxWidth={650} mx={'auto'}>
            <Formtitle title={title} subtitle={subtitle} align="center" />
          </Grid>
          {button && button.href && (
            <Grid
              container
              item
              justifyContent="center"
              sx={{
                mt: -1,
                mb: 2,
              }}
            >
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  window.open(button.href, '_blank')
                }}
              >
                {button.text}
              </Button>
            </Grid>
          )}
          {form.data.funding_organism?.code === 'DPC' && form.data.registration_type == 'SelfRegistration' && (
            <Typography variant="body2" align={'center'} sx={{ mb: 3 }}>
              Vous pouvez consulter à nouveau notre tutoriel d’inscription en cliquant{' '}
              <Link href="https://info.walter-learning.com/guide/inscription-action-dpc/" target="_blank">
                ici
              </Link>
              .
            </Typography>
          )}

          {form.data.registration_type == 'SelfRegistration' && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item>
                <Typography variant="body1" align="center" sx={{ fontWeight: 500 }}>
                  {form.data.funding_organism?.code === 'CPF'
                    ? 'Une connaissance à recommander ?'
                    : 'Un confrère ou une consoeur à recommander ?'}
                </Typography>
                <Typography variant="body2" align="center">
                  Vous connaissez quelqu’un qui pourrait également être interessé par cette formation ? Partagez-nous
                  ses coordonnées !
                </Typography>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Prénom du filleul"
                    variant="outlined"
                    id="first_name"
                    required
                    value={reco.data.first_name}
                    onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                      handleChange('first_name', e?.target.value)
                    }}
                    error={Boolean(reco.errors.first_name)}
                    helperText={reco.errors.first_name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Nom du filleul"
                    id="last_name"
                    variant="outlined"
                    required
                    value={reco.data.last_name}
                    onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                      handleChange('last_name', e?.target.value)
                    }}
                    error={Boolean(reco.errors.last_name)}
                    helperText={reco.errors.last_name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="email"
                    label="Adresse e-mail du filleul"
                    id="email"
                    variant="outlined"
                    required
                    value={reco.data.email}
                    onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                      handleChange('email', e?.target.value)
                    }}
                    error={Boolean(reco.errors.email)}
                    helperText={reco.errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="tel"
                    fullWidth
                    id="mobile_phone"
                    label="N° de mobile du filleul"
                    variant="outlined"
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <MdPhoneEnabled size="20" />
                        </InputAdornment>
                      ),
                    }}
                    value={reco.data.mobile_phone}
                    onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                      handleChange('mobile_phone', e?.target.value)
                    }}
                    error={Boolean(reco.errors.mobile_phone)}
                    helperText={reco.errors.mobile_phone}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    height: {
                      xs: 45,
                    },
                  }}
                  disabled={
                    !(
                      Object.keys(reco.data).every((val) => reco.data[val as keyof DataType]) &&
                      !Object.keys(reco.errors).some((key) => Object.keys(reco.errors).includes(key))
                    )
                  }
                  onClick={() => {
                    submit()
                  }}
                >
                  Envoyer
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {recommanded && (
        <Grid item xs={12} sm={10} md={6}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid
              item
              sx={{
                background: theme.palette.success.main,
                height: 80,
                width: 80,
                borderRadius: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 3,
              }}
            >
              <BsCheck size="40px" style={{ color: 'white' }} />
            </Grid>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              Merci !
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
              Votre recommandation a été transmise avec succès.
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        container
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: 0,
          pt: 2,
          pl: { xs: 2, md: 4 },
          display: 'flex',
        }}
      >
        <Grid item container sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item>
            {location.pathname.includes('/sante') ? (
              <WalterSanteColor
                height="29px"
                onClick={() => {
                  window.open('https://walter-learning.com/sante', '_self')
                }}
              />
            ) : (
              <WalterLearning
                height="29px"
                onClick={() => {
                  window.open('https://walter-learning.com', '_self')
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Divider orientation="vertical" sx={{ height: 40, mx: 2 }} />
          </Grid>
          <Grid
            item
            sx={{
              // flexGrow: { xs: 1, sm: 0 },
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              location.pathname.includes('/sante')
                ? window.open('https://walter-learning.com/sante', '_self')
                : window.open('https://walter-learning.com', '_self')
            }}
          >
            <Retour />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Retour au site
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          mt: 2,
          mr: 4,
          display: { xs: 'none', md: 'block' },
        }}
      >
        <CallUs phone={form.data.theme === 'sante' ? santePhoneNumber : learningPhoneNumber} />
      </Grid>
    </Grid>
  )
}

export default Recommandation
