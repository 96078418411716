import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { FormType } from '../utils/sharedTypes'
import { addPrefixSpecifier } from '../utils/misc'
import { funders } from '../utils/constants'
import { MdExpandMore } from 'react-icons/md'
import FundingSummaryAlma from './FundingSummaryAlma'
import FundingSummaryFIFPL from './FundingSummaryFIFPL'
import FundingSummaryDPC from './FundingSummaryDPC'
import FundingSummaryBankTransfer from './FundingSummaryBankTransfer'
import FundingSummaryDPCPreRegistration from './FundingSummaryDPCPreRegistration'

type Props = {
  form: FormType
}

export default function FundingSummary({ form }: Props) {
  const hasProducts = form.data.products.length > 0 && form.data.product === null
  const listing_price = hasProducts
    ? form.data.funding_organism_registrations.reduce((acc, r) => acc + r.product_listing_price, 0)
    : form.data.product
      ? form.data.product.listing_price
      : 0


  return (
    <Grid item>
      {form.data.funding_organism?.code === 'ALMA' ? (
        <FundingSummaryAlma form={form} />
      ) : form.data.funding_organism?.code === 'BANK_TRANSFER' ? (
        <FundingSummaryBankTransfer form={form} />
      ) : form.data.funding_organism?.code === 'FIFPL' ? (
        <FundingSummaryFIFPL form={form} />
      ) : form.data.funding_organism?.code === 'DPC' ? (
        form.data.pre_registration ? <FundingSummaryDPCPreRegistration form={form} /> : <FundingSummaryDPC form={form} />
      ) : (
        <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
          <Grid item>
            <Typography variant="body2">Prix de la formation (TTC)</Typography>
          </Grid>
          <Typography variant="body1" sx={{ textAlign: 'right', textDecorationLine: 'line-through', fontWeight: 500 }}>
            {Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 0,
            }).format(
              form.data.product_catalog?.funding_price ?? form.data.product_catalog?.listing_price ?? listing_price,
            ) || '-'}
          </Typography>
        </Grid>
      )}

      {hasProducts && (
        <Grid item mt={3}>
          <Accordion>
            <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel2-content" id="panel2-header">
              <Typography variant="body2">Voir le détail de vos inscriptions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item container direction="column" justifyContent="space-between" rowSpacing={1}>
                {form.data.products.map((product) => {
                  if (form.data.funding_organism?.code == 'DPC') {
                    const funding_organism_registration = form.data.funding_organism_registrations.find(
                      (r) => r.product.product_code === product.product_code && r.cancelled === false,
                    )
                    const product_catalog = form.data.product_catalogs.find(pc => pc.product == product.id && pc.funding_organism == form.data.funding_organism?.code)

                    if (!funding_organism_registration && !product_catalog) {
                      return null
                    }

                    return (
                      <Grid item container direction="column" justifyContent="space-between">
                        <Typography variant="body1" fontWeight="500">
                          {product.name}
                        </Typography>
                        <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
                          <Grid item>
                            <Typography variant="body2">Prix de la formation</Typography>
                          </Grid>
                          <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
                            {Intl.NumberFormat('fr-FR', {
                              style: 'currency',
                              currency: 'EUR',
                              maximumFractionDigits: 2,
                            }).format(funding_organism_registration?.product_listing_price || product_catalog?.listing_price || product.listing_price) || '-'}
                          </Typography>
                        </Grid>
                        <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
                          <Grid item>
                            <Typography variant="body2">
                              Prise en charge par{' '}
                              {addPrefixSpecifier(
                                funders.find((p) => p.code === form.data.funding_organism?.code)?.label,
                              ) || '-'}
                            </Typography>
                            <Typography variant="caption">
                              Cette prise en charge est conditionnée à la validation de la formation
                            </Typography>
                          </Grid>
                          <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
                            {Intl.NumberFormat('fr-FR', {
                              style: 'currency',
                              currency: 'EUR',
                              maximumFractionDigits: 2,
                            }).format(Number(funding_organism_registration?.part_orga || product_catalog?.funding_price || product.listing_price)) || '-'}
                          </Typography>
                        </Grid>
                        {/* <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
                          <Grid item>
                            <Typography variant="body2">Reste à charge</Typography>
                          </Grid>
                          <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
                            {Intl.NumberFormat('fr-FR', {
                              style: 'currency',
                              currency: 'EUR',
                              maximumFractionDigits: 2,
                            }).format(funding_organism_registration?.default_personal_funding) || '-'}
                          </Typography>
                        </Grid> */}
                      </Grid>
                    )
                  } else {
                    return (
                      <Grid item container direction="column" justifyContent="space-between">
                        <Typography variant="body1" fontWeight="500">
                          {product.name}
                        </Typography>
                        <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
                          <Grid item>
                            <Typography variant="body2">Prix de la formation</Typography>
                          </Grid>
                          <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
                            {Intl.NumberFormat('fr-FR', {
                              style: 'currency',
                              currency: 'EUR',
                              maximumFractionDigits: 2,
                            }).format(product.listing_price || 0) || '-'}
                          </Typography>
                        </Grid>
                        {/* <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
                          <Grid item>
                            <Typography variant="body2">Reste à charge</Typography>
                          </Grid>
                          <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
                            {Intl.NumberFormat('fr-FR', {
                              style: 'currency',
                              currency: 'EUR',
                              maximumFractionDigits: 2,
                            }).format(funding_organism_registration?.default_personal_funding) || '-'}
                          </Typography>
                        </Grid> */}
                      </Grid>
                    )
                  }
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </Grid>
  )
}
