import { Grid, Typography } from '@mui/material'
import { FormType, ProductType } from '../utils/sharedTypes'

type Props = {
  form: FormType
}

export default function FundingSummaryDPCPreRegistration({ form }: Props) {
  const hasProducts = form.data.products.length > 1 && form.data.product === null

  const getProductCatalogOfProduct = (p: ProductType) => {
    return form.data.product_catalogs.find(pc => pc.product == p.id && pc.funding_organism == form.data.funding_organism?.code)
  }

  const listing_price = form.data.products.map(p => getProductCatalogOfProduct(p)).reduce((acc, pc) => acc + Number(pc?.listing_price || 0), 0)
  const totalPartOrga = form.data.products.map(p => getProductCatalogOfProduct(p)).reduce((acc, pc) => acc + Number(pc?.funding_price || 0), 0)

  return (
    <Grid item container spacing={1}>
      <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
        <Grid item sx={{ maxWidth: '50%' }}>
          <Typography variant="body2">Prix {hasProducts ? 'total de mon parcours de' : 'de ma'} formation</Typography>
        </Grid>
        <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 400 }}>
          {Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
          }).format(listing_price) || '-'}
        </Typography>
      </Grid>

      <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
        <Grid item sx={{ maxWidth: '50%' }}>
          <Typography variant="body2">Montant {hasProducts ? 'total' : ''} pris en charge par l'ANDPC</Typography>
        </Grid>

        <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 400 }}>
          {Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
          }).format(
            totalPartOrga,
          ) || '-'}
        </Typography>
      </Grid>
      {listing_price - totalPartOrga != 0 && (
        <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
          <Grid item sx={{ maxWidth: '50%' }}>
            <Typography variant="body2">Remise commerciale</Typography>
          </Grid>
          <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 400 }}>
            {Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 2,
            }).format(
              listing_price - totalPartOrga,
            ) || '-'}
          </Typography>
        </Grid>
      )}

      <Grid
        item
        container
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-around"
        alignItems={{ xs: 'center', md: 'end' }}
        wrap="nowrap"
        mt={3}
      >
        <Grid item>
          <Typography variant="body1" sx={{ textAlign: 'center', fontSize: 18, fontWeight: 500 }}>
            A payer maintenant
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', fontSize: 25, fontWeight: 500 }}>
            {Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 2,
            }).format(
              form.data.personal_funding !== null
                ? form.data.personal_funding
                : form.data.default_personal_funding ?? 0,
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
